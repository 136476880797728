import { React, useState } from "react";
import { FaArrowRightLong } from "react-icons/fa6";
import { IoLogoDiscord } from "react-icons/io5";

function Footer() {
  const [val, setVal] = useState("");
  function Validate() {
    if (val.startsWith('0x')) { alert('your wallet is whitelisted'); } else { alert('your wallet is not whitelisted'); }
  }
  const change = (event) => {
    setVal(event.target.value);
  }
  return (
    <>
      <footer id="whitelist">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <a href="https://t.co/HG86aVMACz" target="_blank"><img src="/discord-white.png" /></a>
              <h2 className="my-4">Don’t Miss Out, Check your Whitelist</h2>
              <p>Blast Pepe Whitelist Wallet Checker</p>
            </div>
          </div>
          <div className="row">
            <form className="form">
              <input onChange={change} value={val} className="form-control" placeholder="Enter Wallet Address here....." />
              <button onClick={() => Validate()} className="btn btn-theme">SUBMIT BUTTON <FaArrowRightLong /> </button>
            </form>
          </div>
        </div>
      </footer>
      <div className="footer-cta text-center">
        <p>Copyright © 2024 Blast Pepes. All Rights Reserved</p>
      </div>
    </>
  );
}

export default Footer;
