import React from "react";

function MeetOurTeam() {
  return (
    <div id="team" className="roadmap">
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center">
            <h4 className="sub-title">MEET OUR TEAM</h4>
            <h2 className="title">Meets Our Awesome & Cheerful Team</h2>
          </div>
        </div>

        <div className="row">
          <div className="col-md-4">
            <div className="teambox">
              <div className="thumb">
                <img src="/2.png" className="img-fluid" />
              </div>
              <div className="content">
                <h2>Roger Gian</h2>
                <h4>Founder of BlastPepe</h4>
                <p>Previously worked with moonbirds and some other quite well known web3 brands . Chief of strategy and mastermind behind blastpepe</p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="teambox">
              <div className="thumb">
                <img src="/3.png" className="img-fluid" />
              </div>
              <div className="content">
                <h2>Trevor Mia</h2>
                <h4>Co Founder</h4>
                <p>Trevor Mia is a pixel artist who has worked for notable collections which thousands of dollars in volume in the past . Trevor Mia   is the artist behind the cute pepes</p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="teambox">
              <div className="thumb">
                <img src="/4.png" className="img-fluid" />
              </div>
              <div className="content">
                <h2>Monika</h2>
                <h4>CMO</h4>
                <p>Monika is an ex-coinband and has experience in web3 marketing and cryptocurrencies . Currently working for blastpepe</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MeetOurTeam;
