import React from "react";

function MINT() {
  return (
    <>
      <div id="mint" className="roadmap">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2 className="sub-title">MINT</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <iframe id="iframe-widget" src="https://0x28bde6a47bf489a595f02cd528f021cf6756dc98_81457.nfts2.me/?widget=classic&hideBanner=true" style={{ height: '600px', width: '100%', border: 'none' }}></iframe>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MINT;
