import React from "react";
import { FaArrowRightLong } from "react-icons/fa6";


function Mainbanner() {
  return (
    <div id="mainbanner" className="mainBanner">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <h2> Welcome to Blast Pepe</h2>
            <ul>
              <li>Blast Pepe: Unveiling a 4369 collection on Blast Mainnet.</li>
              <li> Unlock one NFT with endless possibilities.</li>
              <li>Join the first community-backed Pepe drop on Blast Chain.</li>
              <li>
                Holders receive $PepeB airdrops and share in all Blast earnings.
              </li>
            </ul>
            <a className="btn btn-theme">Browse Collection <FaArrowRightLong /></a>
          </div>
          <div className="col-md-6">
            <img src="/1.png" className="img-fluid" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Mainbanner;
