import React from 'react'

function Airdrop() {
  return (
    <div id="airdrop" className="airdrop">
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center">
            <h2 className="title">Airdrop To All Holders </h2>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <div className="airbox">
              All blast airdrops redirected towards holders
            </div>
          </div>
          <div className="col-md-6">
            <div className="airbox">
              $pepeb airdrop to all holders , $pepeb will have a liquidity pool
            </div>
          </div>
          <div className="col-md-6">
            <div className="airbox">
              Integration of $pepeb token into blast ecosystem protocol
            </div>
          </div>
          <div className="col-md-6">
            <div className="airbox">
              Staking to earn more multipliers in rewards
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Airdrop