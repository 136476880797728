import React from "react";

function RoadMap() {
  return (
    <div id="roadmap" className="roadmap">
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center">
            <h4 className="sub-title">ROADMAP</h4>
            <h2 className="title">Roadmap Launch Timeline</h2>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 col-lg-5">
            <div className="roadmap-box box-1">
              <div className="content">
                <h2>01</h2>
                <h4>BUILD STRONG COMMUNITY</h4>
                <p>Fostering collaborations and partnerships</p>
              </div>
              <div className="thumb">
                <img src="/5.png" className="img-fluid" />
              </div>
            </div>
          </div>
          <div className="col-md-12 col-lg-2">
            <div className="border-box"></div>
          </div>
          <div className="col-md-12 col-lg-5">
            <div className="roadmap-box box-2">
              <div className="content">
                <h2>02</h2>
                <h4>LAUNCH NFT ASSETS</h4>
                <p>Deploy blast pepes on chain and reward holders with surprises</p>
              </div>
              <div className="thumb">
                <img src="/6.png" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 col-lg-5">
            <div className="roadmap-box box-3">
              <div className="content">
                <h2>03</h2>
                <h4>$PepeB LAUNCH & AIRDROP</h4>
                <p>Make holders rich</p>
              </div>
              <div className="thumb">
                <img src="/7.png" className="img-fluid" />
              </div>
            </div>
          </div>
          <div className="col-md-12 col-lg-2">
            <div className="border-box"></div>
          </div>
          <div className="col-md-12 col-lg-5">
            <div className="roadmap-box box-4">
              <div className="content">
                <h2>04</h2>
                <h4>BUILD STRONG COMMUNITY</h4>
                <p>REDACTED.....<br/>REDACTED.....<br/>REDACTED.....<br/>REDACTED.....</p>
              </div>
              <div className="thumb">
                <img src="/8.png" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RoadMap;
