import React, { useState } from "react";
import { IoLogoDiscord } from "react-icons/io5";

function Header() {

  const [activeClass, setActiveClass] = useState(false);
  const clickHandle = () => {
    setActiveClass(!activeClass)
  }


  return (
    <header>
      <nav className="navbar navbar-expand-lg bg-body-tertiary">
        <div className="container">
          <a className="navbar-brand" href="#">
            <img src="/logo.png" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={() => clickHandle()}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className={activeClass == true ? "collapse navbar-collapse show" : "collapse navbar-collapse justify-content-center"} id="navbarSupportedContent">
            <ul className="navbar-nav mb-2 mb-lg-0">
              <li className="nav-item">
                <a className="nav-link active" aria-current="page" href="#mainbanner">
                  HOME
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link active" aria-current="page" href="#roadmap">
                  ROADMAP
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link active" aria-current="page" href="#airdrop">
                  $PepeB
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link active" aria-current="page" href="#mint">
                  MINT
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link active" aria-current="page" href="#team">
                  TEAM
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link active" aria-current="page" href="#whitelist">
                  WHITE LIST
                </a>
              </li>
            </ul>
          </div>
          <div className="discord-icon">
            <a href="https://t.co/HG86aVMACz" target="_blank" className="btn btn-theme">JOIN DISCORD <IoLogoDiscord /></a>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Header;
