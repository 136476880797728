import React from 'react'
import Mainbanner from './Mainbanner'
import RoadMap from './RoadMap'
import Airdrop from './Airdrop'
import MINT from './MINT'
import MeetOurTeam from './MeetOurTeam'

function Home() {
  return (
    <>
        <Mainbanner/>
        <RoadMap/>
        <Airdrop/>
        <MeetOurTeam/>
        <MINT/>
    </>
  )
}

export default Home